import * as z from "zod";

export const ShoppingCartItemModel = z.object({
  id: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  quantity: z.coerce.number().int(),
  shoppingCartId: z.coerce.number().int().nullish(),
  metadata: z.string().nullish(),
  quantityClaimed: z.coerce.number().int(),
  columnIndex: z.coerce.number().int(),
  label: z.string(),
  priceModifier: z.coerce.number(),
  sectionId: z.string(),
  rowIndex: z.coerce.number().int(),
  ticketPriceId: z.coerce.number().int(),
});
